import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Box,
  IconButton,
} from '@mui/material';
import BookingModal from './BookingModal';
import { Delete, Edit } from '@mui/icons-material'; // Import delle icone per modifica ed eliminazione
import * as XLSX from 'xlsx';

const BookingTable = ({
  bookings,
  stops,
  getBookings,
  onAdd,
  onEdit,
  onDelete,
  searchTerm,
  setSearchTerm,
  users,
  addUser,
  addVehicle,
  pitches,
  vehicles,
  getVehicles,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [filteredBookings, setFilteredBookings] = useState(bookings);
  const [currentBooking, setCurrentBooking] = useState(null);

  useEffect(() => {
    filterBookings();
  }, [searchTerm, bookings]);

  const handleEdit = (booking) => {
    setCurrentBooking(booking);
    setOpenModal(true);
  };

  const handleDelete = (id) => {
    if (window.confirm('Sei sicuro di voler eliminare questa prenotazione?')) {
      onDelete(id);
    }
  };

  const handleModalClose = () => {
    setCurrentBooking(null);
    setOpenModal(false);
  };

  const filterBookings = () => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = bookings.filter((booking) => {
      return (
        booking.users?.firstName?.toLowerCase().includes(lowercasedFilter) ||
        booking.users?.lastName?.toLowerCase().includes(lowercasedFilter) ||
        booking.users?.phone?.toLowerCase().includes(lowercasedFilter) ||
        booking.vehicles?.licensePlate
          ?.toLowerCase()
          .includes(lowercasedFilter) ||
        booking.restareas?.friendlyName
          ?.toLowerCase()
          .includes(lowercasedFilter) ||
        formatDate(booking.start)?.toLowerCase().includes(lowercasedFilter) ||
        formatDate(booking.end)?.toLowerCase().includes(lowercasedFilter) ||
        String(booking.price)?.toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredBookings(filteredData);
  };

  const formatDate = (unixTime) => {
    const date = new Date(unixTime * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      bookings.map((booking) => ({
        Nome: booking.users.firstName,
        Cognome: booking.users.lastName,
        Telefono: booking.users.phone,
        Targa: booking.vehicles.licensePlate,
        Piazzola: booking.restareas.friendlyName,
        'Inizio Sosta': formatDate(booking.start),
        'Fine Sosta': formatDate(booking.end),
        Prezzo: booking.price,
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Bookings');
    XLSX.writeFile(wb, 'bookings.xlsx');
  };

  return (
    <div>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModal(true)}
          >
            Aggiungi Prenotazione
          </Button>
          <Button variant="contained" color="secondary" onClick={exportToExcel}>
            Esporta in Excel
          </Button>
          <TextField
            size="small"
            label="Cerca"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Cognome</TableCell>
                <TableCell>Telefono</TableCell>
                <TableCell>Targa</TableCell>
                <TableCell>Piazzola</TableCell>
                <TableCell>Inizio Sosta</TableCell>
                <TableCell>Fine Sosta</TableCell>
                <TableCell>Prezzo</TableCell>
                <TableCell>Azioni</TableCell> {/* Colonna per le azioni */}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBookings.map((booking) => (
                <TableRow key={booking.id}>
                  <TableCell>{booking.users?.firstName || 'N/A'}</TableCell>
                  <TableCell>{booking.users?.lastName || 'N/A'}</TableCell>
                  <TableCell>{booking.users?.phone || 'N/A'}</TableCell>
                  <TableCell>
                    {booking.vehicles?.licensePlate || 'N/A'}
                  </TableCell>
                  <TableCell>
                    {booking.restareas?.friendlyName || 'N/A'}
                  </TableCell>
                  <TableCell>{formatDate(booking.start)}</TableCell>
                  <TableCell>{formatDate(booking.end)}</TableCell>
                  <TableCell>{booking.price} €</TableCell>
                  <TableCell>
                    {/* Pulsanti per Modifica ed Elimina */}
                    {/* <IconButton onClick={() => handleEdit(booking)}>
                      <Edit />
                    </IconButton> */}
                    <IconButton onClick={() => handleDelete(booking.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <BookingModal
        open={openModal}
        booking={currentBooking}
        users={users}
        addUser={addUser}
        addVehicle={addVehicle}
        pitches={pitches}
        vehicles={vehicles}
        stops={stops}
        onClose={handleModalClose}
        onSave={async (booking) => {
          if (currentBooking) {
            await onEdit(booking); // Modifica la prenotazione esistente
          } else {
            await onAdd(booking); // Aggiungi una nuova prenotazione
          }
          await getBookings(); // Ricarica l'elenco delle prenotazioni dopo l'operazione
          await getVehicles(); // Ricarica l'elenco dei veicoli dopo l'operazione
          handleModalClose(); // Chiudi la modale
        }}
      />
    </div>
  );
};

export default BookingTable;
